import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="98vh"
          maxHeight="720px"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyStart
          alignCenter
        >
          <CFImage
            src={mobileHeroText}
            w="100%"
            maxWidth="350px"
            alt="About"
            mt="20px"
          />
          <CFView pulsate textCenter pv="20px" w="90%">
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="#E89A26" ph="60px" pv="3px">
            <CFImage src={heroText} w="100%" maxWidth="330px" alt="About" />
            <CFView row center mt="5px">
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
